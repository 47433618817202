import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'S2tHnNU5ABaNCU2npdhIhOXDQCnFM4J41Krk',
  sdkSecret: 'oCXdVvQTaa5ShlCCB8AWpsyHMe60aoUNExg5',
  topic: 'Zafer Bayramı',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: '30.08',
  signature: '',
};
